<template>
  <div>
    <sidebar-search-product
      v-if="!purchaseOrderId && !isMobile && !stockAdjustmentNoteId"
      class="print-dp-none"
      :status="status"
      @productSelecting="productSelecting($event)">
      <template v-slot:header>
        <h6 class="text-h6">
          Delivery Note
        </h6>
        <p class="text-subtitle-1 mb-0">
          เลือกสินค้าเพื่อออกใบนำสินค้าเข้า
        </p>
      </template>
    </sidebar-search-product>
    <v-app-bar
      class="print-dp-none"
      app>
      <div class="app-bar-title">
        <v-btn
          v-if="isDraft"
          color="primary"
          :disabled="isDisableCreateBtn"
          small
          @click="submitNote()">
          Create
        </v-btn>
        <v-btn
          v-if="isPending && !isMobile"
          color="error"
          small
          :disabled="isDisabledByRole"
          @click="tryToUpdateNote('cancel')">
          ยกเลิกใบนำเข้า
        </v-btn>

        <v-btn
          v-if="isPending"
          fab
          icon
          dark
          small
          color="secondary"
          @click="showRFIDModal = true">
          <v-icon>mdi-cube-scan</v-icon>
        </v-btn>
        <v-text-field
          v-if="isPending"
          v-model="productQr"
          class="mx-2"
          autofocus
          placeholder="คลิ๊กที่ช่องนี้เพื่อสแกน QR Code"
          hide-details
          dense
          solo
          @keydown.enter="enterQr" />
        <v-spacer v-if="!isPending"></v-spacer>
        <div v-if="isMobile">
          <v-menu
            v-if="(isPending || isDraft)"
            offset-y>
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                icon
                dark
                small
                color="secondary"
                v-bind="attrs"
                v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="isDraft"
                link
                :disabled="isDisableCreateBtn"
                @click="submitNote()">
                <v-list-item-title>
                  สร้าง
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending"
                link
                :disabled="isDisableCreateBtn || isDisabledByRole"
                @click="submitNote('pending')">
                <v-list-item-title>
                  บันทึก
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending && isShowSetCount"
                link
                :disabled="isDisabledByRole"
                @click="setCount()">
                <v-list-item-title>
                  Set Count
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending"
                link
                :disabled="isDisableBtnApprove || isDisabledByRole || !isFormValid"
                @click="approve()">
                <v-list-item-title>
                  Approve
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="isPending && !isMobile"
                link
                :disabled="isDisabledByRole"
                @click="tryToUpdateNote('cancel')">
                <v-list-item-title>
                  ยกเลิกใบนำเข้า
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <template v-else>
          <v-btn
            v-if="isPending && isShowSetCount"
            small
            :disabled="isDisabledByRole"
            @click="setCount()">
            Set Count
          </v-btn>
          <v-btn
            v-if="isPending"
            :disabled="isDisableCreateBtn || isDisabledByRole"
            small
            color="success"
            @click="submitNote('pending')">
            บันทึก
          </v-btn>
          <v-btn
            v-if="isPending"
            :disabled="isDisableBtnApprove || isDisabledByRole || !isFormValid"
            color="success"
            small
            @click="approve()">
            Approve
          </v-btn>
          <v-btn
            v-if="!isBlank && !isDraft && !isCanceled"
            small
            @click="print()">
            <v-icon
              class="mr-2"
              small>
              mdi-printer
            </v-icon>
            <span>Print</span>
          </v-btn>
          <v-btn
            v-if="(isPending || isApproved)"
            color="primary"
            small
            :loading="loadingCalPrint"
            @click="selectPrintQr()">
            <v-icon
              class="mr-2"
              small>
              mdi-qrcode
            </v-icon>
            Print QR
          </v-btn>
          <v-btn
            v-if="isDraft"
            color="success"
            @click="showImportDialog()">
            Import
          </v-btn>
        </template>
      </div>
    </v-app-bar>

    <v-main>
      <v-form
        ref="form"
        v-model="isFormValid"
        @input="inputValidate($event)">
        <page-a4
          :note-ref="noteRef"
          :note-id="noteCode"
          :products="productsSelected"
          :status="status"
          :print-by="printBy"
          :create-by="createBy"
          :out-of-limit="outOfLimit"
          :warehouse="warehouseSelected"
          :print-time="printTime"
          :note.sync="note"
          :update-by="updateBy"
          :reason.sync="reason"
          :bc-ref.sync="bcRef"
          :update-time="updateTime"
          :count="count"
          note-type="deliveryNote"
          :transfer-warehouse-id.sync="transferWarehouseId"
          :warehouse-items="warehouseItems"
          :qr="qr"
          :is-mobile="isMobile"
          @remove-product="removeProduct($event)"
          @remove-sku="removeSku($event)">
          <template v-slot:title>
            <div class="page-a4-title">
              <h1 class="mr-2">
                ใบนำสินค้าเข้า
              </h1>
            </div>
          </template>
        </page-a4>
      </v-form>
    </v-main>
    <v-dialog
      v-model="showPrintQr"
      persistent
      width="300px">
      <v-card>
        <v-card-title class="headline">
        </v-card-title>
        <v-card-text>
          <div
            v-if="missingFitFields.length"
            class="my-2">
            <p class="text-bold mb-1 red--text">
              พบสินค้า {{ missingFitFields.length }} ชิ้น ที่ไม่ระบุ Fit ดังนี้
            </p>
            <div
              style="max-height: 300px; overflow: scroll;">
              <table>
                <tr
                  v-for="(skuCode, index) in missingFitFields"
                  :key="index">
                  <td>
                    {{ index + 1 }}.
                  </td>
                  <td>
                    {{ skuCode }}
                  </td>
                </tr>
              </table>
            </div>
            <v-divider class="mb-4"></v-divider>
          </div>
          <v-btn
            block
            color="primary"
            @click="printQr('a4')">
            Print A4
          </v-btn>
          <v-btn
            class="mt-3"
            dark
            block
            color="red darken-2"
            @click="printQr('godex')">
            <v-icon class="mr-2">
              mdi-printer
            </v-icon>
            Print Godex
          </v-btn>
          <v-btn
            class="mt-3"
            :loading="exporting"
            block
            dark
            color="indigo darken-2"
            @click="printQr('rfid')">
            <v-icon class="mr-2">
              mdi-access-point
            </v-icon>
            EXPORT RFID
          </v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="showPrintQr = false">
            ปิด
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showImport"
      persistent
      width="80vw"
      max-width="450px">
      <v-card>
        <v-card-title class="headline">
          <label>Import CSV</label>
          <v-spacer />
          <v-btn
            icon
            @click="showImport = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div class="d-flex flex-column justify-center align-center mt-4">
            <img
              src="@/assets/image/import-csv-example.png"
              alt="Import csv example" />
            <p>
              ตัวอย่างไฟล์ที่สามารถ Upload ได้ (นามสกุล csv)
            </p>

            <div style="width: 80%;">
              <csv-reader
                label="เลือกไฟล์ .csv"
                @input="onSelectCsv($event)" />
            </div>

            <div
              v-if="invalidData.length"
              style="width: 100%;">
              <p class="mt-4 mb-2 font-weight-bold">
                รายการที่ Error
              </p>
              <v-data-table
                :headers="headers"
                :items="invalidData"
                :items-per-page="-1"
                hide-default-footer
                class="red--text">
              </v-data-table>
            </div>
          </div>
        </v-card-text>
        <v-card-actions
          v-if="isReaded && !invalidData.length"
          class="justify-center align-center pb-6">
          <v-btn
            color="success"
            @click="onConfirmCsv()">
            ยืนยัน
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <RFIDScanner
      v-model="showRFIDModal"
      :items="productsSelected"
      :alias-ids="aliasIds"
      :compare="status !== 'draft' || !!purchaseOrderId"
      @confirm="onScanned($event)" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getAuthDecode, getRole } from '@/assets/js/Authentication'
import ConsumerTypes from '@/assets/js/ConsumerTypes'
import InventoryProvider from '@/resources/InventoryProvider'
import PurchaseOrdersProvider from '@/resources/PurchaseOrdersProvider'
import ProductProvider from '@/resources/ProductProvider'
import ProductCollectionProvider from '@/resources/ProductCollectionProvider'
import StockCountAdjustmentProvider from '@/resources/StockCountAdjustmentProvider'
import SizeValues from '@/assets/js/SizeValues'
import { generateQRCode, setPrintItem } from '@/assets/js/helper'
import CsvReader from '@/views/management/components/CsvReader.vue'
import SidebarSearchProduct from '../components/SidebarSearchProduct.vue'
import PageA4 from '../components/PageA4.vue'
import RFIDScanner from '../components/RFIDScanner.vue'
import RFIDGenerator from '@/assets/js/RFIDGenerate'
import { ExportToCsv } from 'export-to-csv'

const InventoryService = new InventoryProvider()
const PurchaseOrdersService = new PurchaseOrdersProvider()
const stockCountAdjustmentService = new StockCountAdjustmentProvider()
const ProductService = new ProductProvider()
const ProductCollectionService = new ProductCollectionProvider()

export default {
  components: {
    sidebarSearchProduct: SidebarSearchProduct,
    pageA4: PageA4,
    CsvReader,
    RFIDScanner
  },
  data () {
    return {
      headers: [
        {
          text: 'Row',
          value: 'row'
        },
        {
          text: 'SKU',
          value: 'sku'
        },
        {
          text: 'QTY',
          value: 'qty'
        }
      ],
      collections: [],
      productsSelected: [],
      noteRef: null,
      status: '',
      productQr: '',
      printBy: getAuthDecode().email,
      noteCode: '-',
      createBy: '-',
      note: '',
      noteWarehouse: 0,
      qr: null,
      reason: null,
      printTime: new Date().toString(),
      updateBy: '-',
      updateTime: new Date().toString(),
      loading: false,
      validateFormTimeout: null,
      valid: false,
      transferNote: false,
      transferWarehouseId: null,
      ref: null,
      bcRef: '',
      isFormValid: false,
      showPrintQr: false,
      webUrl: 'gentlewomanonline.com',
      showImport: false,
      isReaded: false,
      validData: [],
      invalidData: [],
      showRFIDModal: false,
      aliasIds: {},
      poCreateDate: null,
      exporting: false,
      missingFitFields: [],
      loadingCalPrint: false,
      stockAdjustmentNote: null,
      saveClicked: false
    }
  },
  computed: {
    ...mapGetters({
      warehouse: 'Store/warehouse',
      storeSetting: 'Store/storeSetting',
      store: 'Store/store'
    }),
    warehouseItems () {
      return this.warehouse.filter((r) => r.id !== this.warehouseId && r.id !== 0)
    },
    isDisabledByRole () {
      const dnRole = [
        'management',
        'area_manager',
        'marketing',
        'developer',
        'creative',
        'merchandising_planner',
        'merchandiser',
        'online_admin',
        'vm',
        'accounting_manager',
        'inventory_and_costing',
        'warehouse_manager',
        'warehouse',
        'store_manager',
        'acting_assist_store_manager',
        'assist_store_manager',
        'sales_staff'
      ]
      const role = getRole()
      return !dnRole.some((r) => r === role)
    },
    isShowSetCount () {
      const role = getRole()
      const setCountRoles = [
        'auth',
        'management',
        'area_manager',
        'developer',
        'merchandising_planner',
        'merchandiser',
        'warehouse_manager',
        'warehouse',
        'inventory_and_costing'
        // 'store_manager',
        // 'assist_store_manager'
      ]
      return setCountRoles.some((r) => r === role)
    },
    isTransferNote () {
      return this.transferNote || (this.transferWarehouseSelected && this.transferWarehouseSelected.id > 0)
    },
    isDisableCreateBtn () {
      if (this.reason === 'STOCK_COUNT') {
        return !this.valid || this.productsSelected.length === 0 || this.bcRef === ''
      }
      return !this.valid || this.productsSelected.length === 0 || this.reason === null
    },
    isDisableSaveDraft () {
      return !this.valid || this.productsSelected.length === 0 || this.reason === null
    },
    warehouseSelected () {
      const id = this.warehouseId || 0
      return this.findWarehouse(id)
    },
    transferWarehouseSelected () {
      const id = this.transferWarehouseId || 0
      return this.findWarehouse(id)
    },
    warehouseId () {
      let warehouseId = 0
      if (this.noteWarehouse) {
        warehouseId = this.noteWarehouse
      } else if (this.$route.query.warehouse) {
        warehouseId = parseInt(this.$route.query.warehouse)
      }
      return warehouseId
    },
    purchaseOrderId () {
      return this.$route.query.poNo || null
    },
    stockAdjustmentNoteId () {
      return this.$route.query.stockAdjustmentNoteId || null
    },
    assemblyOrderId () {
      return this.$route.query.aoNo || null
    },
    noteId () {
      return this.$route.query.id || null
    },
    isDraft () {
      return this.status === 'draft'
    },
    isPending () {
      return this.status === 'pending' && this.reason !== 'ASSEMBLY_ORDER'
    },
    isCanceled () {
      return this.status === 'canceled'
    },
    isApproved () {
      return this.status === 'approved'
    },
    isBlank () {
      return this.status === ''
    },
    count () {
      return this.productsSelected.reduce((total, p) => total + p.skus.reduce((sum, s) => sum + Number(s.count), 0), 0)
    },
    outOfLimit () {
      return this.productsSelected.some((pd) => !!pd.skus.some((sku) => sku.amount < sku.count))
    },
    isDisableBtnApprove () {
      return this.count <= 0 || this.outOfLimit
    },
    isMobile () {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm
    },
    isShowFit () {
      return this.store?.id === '645a1d00765d5519beeb97f6'
    }
  },
  created () {
    this.initials()
  },
  beforeDestroy () {
    if (!this.saveClicked && this.stockAdjustmentNoteId) {
      this.stockCountAdjustmentService.updateAdjustmentNoteStatus({ stockAdjustmentNoteId: this.stockAdjustmentNoteId, status: 'inactive' })
    }
    this.removeListener()
    window.removeEventListener('afterprint', this.handleAfterPrint)
  },
  mounted () {
    window.addEventListener('afterprint', this.handleAfterPrint)
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading'
    }),
    initials () {
      window.onbeforeunload = () => 'Changes you made may not be saved.'
      window.onbeforeprint = () => {
        this.printTime = new Date().toString()
      }

      if (this.noteId) {
        this.fetchNote()
        this.saveClicked = true
      } else if (this.purchaseOrderId) {
        this.status = 'draft'
        this.fetchPurchaseOrder(this.purchaseOrderId)
      } else if (this.stockAdjustmentNoteId) {
        this.status = 'draft'
        this.fetchStockAdjustmentNote(this.stockAdjustmentNoteId)
      } else {
        this.status = 'draft'
      }

      this.getCollections()
    },
    async getCollections () {
      try {
        this.loading = true

        const { data } = await ProductCollectionService.getAll({
          page: 1,
          limit: 999,
          sortBy: 'createdAt',
          sortOrder: 'desc'
        })

        this.collections = data.results.map((c) => ({
          id: c.id,
          name: c.name,
          nickname: c.nickname
        }))
      } catch (error) {
        console.error('getCollections', error)
      } finally {
        this.loading = false
      }
    },
    async onScanned (items) {
      if (this.status === 'draft') {
        const codes = items.map((v) => v.code)
        const itemsInNote = this.productsSelected.filter((v) => v.skus.some((s) => codes.includes(s.code)))
        const codesInNote = itemsInNote.reduce((arr, i) => [...arr, ...i.skus.map((v) => v.code)], [])
        const currentItems = items.filter((v) => codesInNote.includes(v.code))
        const newItems = items.filter((v) => !codesInNote.includes(v.code))

        if (currentItems.length) {
          currentItems.forEach((item) => {
            const index = this.productsSelected.findIndex((v) => v.skus.some((s) => s.code === item.code))
            const skuIndex = this.productsSelected[index].skus.findIndex((v) => v.code === item.code)

            this.productsSelected[index].skus[skuIndex].amount += item.count
          })
        }

        if (newItems.length) {
          const { products, skus } = await this.getProductByCodes(newItems.map((v) => v.code))
          const selected = []

          newItems.forEach((v) => {
            const sku = skus[v.code] || null

            if (sku) {
              const product = products[sku.productId]

              selected.push({
                id: product.id,
                name: product.name,
                model: product.model,
                brand: product.brand,
                variant: sku.variant,
                photoUrls: product.photoUrls,
                skus: [
                  {
                    ...sku,
                    amount: Number(v.count)
                  }
                ]
              })
            }
          })

          // this.productsSelected = []
          selected.forEach((v) => this.productSelecting(v))
        }
      } else {
        for (const item of items) {
          for (let i = 0; i < this.productsSelected.length; i++) {
            const index = this.productsSelected[i].skus.findIndex((sku) => sku.code === item.code)

            if (index !== -1) {
              this.productsSelected[i].skus[index].count += item.count
            }
          }
        }
      }

      this.showRFIDModal = false
    },
    showImportDialog () {
      this.validData = []
      this.invalidData = []
      this.isReaded = false
      this.showImport = true
    },
    onSelectCsv (data) {
      if (!data) {
        this.validData = []
        this.invalidData = []
        this.isReaded = false
        return
      }

      const validateFields = [
        {
          name: 'sku',
          type: 'string'
        },
        {
          name: 'qty',
          type: 'number'
        }
      ]

      const headerMatch = validateFields.every((v) => data.headers.includes(v.name))

      if (data.headers.length !== validateFields.length || !headerMatch) {
        this.setSnackbar({
          value: true,
          message: 'หัวตารางต้องมีแค่ sku และ qty เท่านั้น',
          type: 'error'
        })
        return
      }

      data.results.forEach((v, i) => {
        const valid = validateFields.every((f) => {
          if (f.type === 'number') {
            return f.name in v && !Number.isNaN(v[f.name]) && !Number.isNaN(parseInt(v[f.name]))
          }

          return f.name in v && v[f.name] === String(v[f.name])
        })

        const index = this.validData.findIndex((d) => d.sku === v.sku)

        if (valid && index === -1) {
          this.validData.push({
            ...v,
            qty: Number(v.qty)
          })
        } else if (valid) {
          this.validData[index].qty += Number(v.qty)
        } else {
          this.invalidData.push({
            row: i + 2,
            ...v
          })
        }
      })

      this.isReaded = true
    },
    async selectPrintQr () {
      this.missingFitFields = []
      this.loadingCalPrint = true
      if (this.isShowFit) {
        const { data: foundProducts } = await ProductService.getManyProductById({
          productIds: this.productsSelected.map((r) => r.id)
        })
        for (const product of this.productsSelected) {
          const fPd = foundProducts.find((p) => p.id === product.id)
          // eslint-disable-next-line no-await-in-loop
          await Promise.all(
            product.skus.map(async (sku) => {
              const fitText = this.getFitting(fPd.tags)
              if (fitText === 'FIT : -') {
                this.missingFitFields.push(sku.code)
              }
            })
          )
        }
      }
      this.loadingCalPrint = false
      this.showPrintQr = true
    },
    enterQr () {
      const qr = this.productQr.toUpperCase().trim()
      const webUrl = this.storeSetting.webUrl.toUpperCase()
      const isStoreUrl = qr.includes(`${webUrl}/`)
      const storeUrl = isStoreUrl ? `${webUrl}/` : `${webUrl}/`.replace('WWW.', '')
      const codes = qr.split(storeUrl)
      const code = codes[codes.length - 1]
      let isFound = false

      for (let pInd = 0; pInd < this.productsSelected.length; pInd++) {
        const index = this.productsSelected[pInd].skus.findIndex((sku) => sku.code.toUpperCase() === code)
        if (index !== -1) {
          this.productsSelected[pInd].skus[index].count = Number(this.productsSelected[pInd].skus[index].count) + 1
          isFound = true
        }
      }

      if (!isFound) {
        this.setSnackbar({
          value: true,
          message: `${code} not found.`,
          type: 'error'
        })
      }

      this.productQr = ''
    },
    removeListener () {
      window.removeEventListener('onbeforeunload')
      window.removeEventListener('onbeforeprint')
    },
    findWarehouse (id) {
      const warehouse = this.warehouse.find((r) => +r.id === +id)
      return {
        id: warehouse.id,
        name: warehouse.name,
        code: warehouse.code
      }
    },
    inputValidate () {
      this.validateForm()
    },
    async getSkusAliasByCodes (codes) {
      try {
        this.setLoading({ active: true })

        const promises = await Promise.all(codes.map(async (code) => {
          const { data } = await ProductService.getSkuAliasIdByCode(code)

          return data
        }))

        this.aliasIds = promises.reduce((obj, v) => {
          const tmpObj = { ...obj }
          const code = Object.keys(v)[0]
          const aliasId = v[code]

          tmpObj[aliasId] = code.toUpperCase()

          return tmpObj
        }, {})
      } catch (error) {
        console.error('getSkusAliasByCodes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async onConfirmCsv () {
      try {
        this.setLoading({ active: true })

        const { products, skus } = await this.getProductByCodes(this.validData.map((v) => v.sku))
        const selected = []

        this.validData.forEach((v, i) => {
          const sku = skus[v.sku] || null

          if (sku) {
            const product = products[sku.productId]

            selected.push({
              id: product.id,
              name: product.name,
              model: product.model,
              brand: product.brand,
              variant: sku.variant,
              photoUrls: product.photoUrls,
              skus: [
                {
                  ...sku,
                  amount: Number(v.qty)
                }
              ]
            })
          } else {
            this.invalidData.push({
              ...v,
              row: i + 2
            })
          }
        })

        if (!this.invalidData.length) {
          this.productsSelected = []
          selected.forEach((v) => this.productSelecting(v))

          this.showImport = false
          this.isReaded = false
          this.validData = []
          this.invalidData = []
        }
      } catch (error) {
        console.error('onConfirmCsv', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async getProductByCodes (codes) {
      try {
        this.setLoading({ active: true })

        const { data } = await ProductService.getManyProductByCode({ codes })

        if (!data.length) {
          throw Error('Product not found.')
        }

        const products = data.reduce((obj, p) => {
          const tmpObj = { ...obj }
          tmpObj[p.id] = p

          return tmpObj
        }, {})
        const skus = data.reduce((obj, p) => {
          const tmpObj = { ...obj }

          p.skus.forEach((v) => {
            tmpObj[v.code] = v
          })

          return tmpObj
        }, {})

        return { products, skus }
      } catch (error) {
        console.error('getProductByCodes', error)
        this.setSnackbar({
          value: true,
          message: error?.message || error,
          type: 'error'
        })

        return null
      } finally {
        this.setLoading({ active: false })
      }
    },
    productSelecting (event) {
      const product = JSON.parse(JSON.stringify({
        ...event,
        photoUrl: event.photoUrl || event.photoUrls[0]
      }))
      const duplicateIndex = this.productsSelected.findIndex((r) => r.id === product.id)
      if (duplicateIndex !== -1 && this.productsSelected[duplicateIndex].id) {
        const duplicateProduct = this.productsSelected[duplicateIndex]
        for (let index = 0; index < product.skus.length; index++) {
          const sku = product.skus[index]
          const skuIndex = duplicateProduct.skus.findIndex((r) => r.id === sku.id)
          if (skuIndex !== -1 && duplicateProduct.skus[skuIndex].id) {
            duplicateProduct.skus[skuIndex].amount++
          } else {
            sku.color = sku.color.name || ''
            sku.size = sku.size.name || ''
            sku.amount = sku?.amount || 1
            sku.count = 0
            duplicateProduct.skus.push(sku)
          }
        }
        this.productsSelected.splice(duplicateIndex, 1, duplicateProduct)
      } else {
        product.skus = product.skus.map((sku) => ({
          ...sku,
          color: sku.color.name || '',
          size: sku.size.name || '',
          amount: sku?.amount || 1,
          count: 0
        }))
        this.productsSelected.push(product)
      }
    },
    validateForm () {
      clearTimeout(this.validateFormTimeout)
      this.validateFormTimeout = setTimeout(() => {
        this.valid = this.$refs.form.validate()
      }, 50)
    },
    setCount () {
      for (let i = 0; i < this.productsSelected.length; i++) {
        const product = this.productsSelected[i]
        for (let j = 0; j < product.skus.length; j++) {
          const sku = product.skus[j]
          sku.count = sku?.maxAmount || sku.amount
        }
      }
    },
    removeProduct (index) {
      this.productsSelected.splice(index, 1)
    },
    removeSku ({ productIndex, skuIndex }) {
      if (this.productsSelected[productIndex].skus.length > 1) {
        this.productsSelected[productIndex].skus.splice(skuIndex, 1)
      } else {
        this.removeProduct(productIndex)
      }
    },
    getFitting (tags) {
      if (this.isShowFit) {
        if (tags && tags.length) {
          const fittingTag = tags.find((tag) => ['UNISEX', 'WOMEN', 'KIDS', 'MEN'].includes(tag.toUpperCase()))
          if (fittingTag) {
            return `${fittingTag.toUpperCase()}`
          }
        }
        return 'FIT : -'
      }
      return ''
    },
    async printQr (type) {
      if (type === 'rfid') this.exporting = true
      const { webUrl } = this.storeSetting
      const { data: foundProducts } = await ProductService.getManyProductById({
        productIds: this.productsSelected.map((r) => r.id)
      })
      const mapPrintItems = []
      for (const product of this.productsSelected) {
        const fPd = foundProducts.find((p) => p.id === product.id)
        const collection = this.collections.find((c) => c.id === fPd?.gwCollection)
        // eslint-disable-next-line no-await-in-loop
        const skus = await Promise.all(
          product.skus.map(async (sku) => {
            const url = `${(webUrl)}/${sku.code}`
            const qr = await generateQRCode(url)
            const merCode = product.name.split('-')
            const prototypeCategory = fPd?.productPrototype?.category || 'UNKNOWN'
            const productionDate = this.poCreateDate || this.$dayjs().subtract(2, 'month').format('MM-YYYY')
            const aliasId = Object.keys(this.aliasIds).find((key) => this.aliasIds[key] === sku.code)

            if (type === 'rfid') {
              return {
                aliasId: +aliasId,
                amount: sku.amount,
                code: sku.code,
                collection: collection?.nickname ? collection?.nickname : 'COLLECTION : -',
                color: sku.color,
                size: sku.size,
                url,
                consumerType: ConsumerTypes(prototypeCategory.toLowerCase()),
                productionDate,
                price: `${sku.price.toLocaleString()} THB`,
                fitting: this.getFitting(fPd.tags)
              }
            }
            return {
              qr,
              gwCollection: collection?.nickname ? collection?.nickname : 'COLLECTION : -',
              options: `${sku.color.toUpperCase()} - ${sku.size.toUpperCase()}`,
              price: sku.price,
              productName: merCode[merCode.length - 1],
              skuCode: sku.code,
              amount: this.isApproved ? sku.count : sku.amount,
              consumerType: ConsumerTypes(prototypeCategory.toLowerCase()),
              productionDate,
              fitting: this.getFitting(fPd?.tags)
            }
          })
        )
        if (type === 'rfid') this.exporting = false
        mapPrintItems.push(...skus)
      }
      setPrintItem(mapPrintItems)
      if (type === 'a4') {
        // eslint-disable-next-line no-alert
        alert('กรุณาเลือกขนาดกระดาษเป็น A4\nรูปแบบ: แนวตั้ง\nระยะขอบกระดาษ: ค่าเริ่มต้น\nติ๊กส่วนหัวและส่วนท้ายของกระดาษออก')
        const routeData = this.$router.resolve({ name: 'QRcodeA4Page' })
        window.open(routeData.href, '_blank')
      } else if (type === 'rfid') {
        try {
          const rfid = new RFIDGenerator(mapPrintItems)
          const generated = await rfid.generateWithBatch()
          const timeStringFormat = this.$dayjs().format('DD_MM_YYYY_HHmm')
          const options = {
            filename: `${this.store.uniqueCode}_RFID_${timeStringFormat}`,
            showLabels: true,
            useKeysAsHeaders: true
          }

          const csvExporter = new ExportToCsv(options)
          csvExporter.generateCsv(generated)
        } catch (error) {
          console.error('exportCsv', error)
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: error?.message || error,
            type: 'error'
          })
        }
      } else {
        // eslint-disable-next-line no-alert
        alert('กรุณาเลือกขนาดกระดาษเป็น 10x6\nรูปแบบ: แนวตั้ง\nระยะขอบกระดาษ: ปิด\nติ๊กส่วนหัวและส่วนท้ายของกระดาษออก')
        const routeData = this.$router.resolve({ name: 'QRcodeGodexPage' })
        window.open(routeData.href, '_blank')
      }
    },
    async submitNote (status) {
      try {
        this.loading = true
        if (this.noteId && status) {
          const note = await this.updateNote(status)
          this.setNoteData(note)
        } else if (this.noteId) {
          const note = await this.updatePendingNote()
          this.setNoteData(note)
        } else {
          const note = await this.createNote(status)
          if (this.stockAdjustmentNoteId) {
            const skusInNote = this.productsSelected.flatMap((product) => product.skus.map((sku) => sku.id))
            await this.updateNoteInStockAdjustment({
              noteId: note.id,
              noteCode: note.code,
              stockCountAdjustmentId: this.stockAdjustmentNote.stockCountAdjustment,
              stockAdjustmentNoteId: this.stockAdjustmentNoteId,
              noteType: 'dn',
              skusInNote
            })
          }
          this.$router.push({
            name: this.$route.name,
            query: {
              id: note.id,
              warehouse: this.warehouseId
            }
          })
          this.initials()
        }
      } catch (error) {
        console.error('submitNote', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async renewNote () {
      try {
        this.loading = true
        const note = await this.createNote('draft')
        this.$router.push({
          name: this.$route.name,
          query: {
            id: note.id,
            warehouse: this.warehouseId
          }
        })
        this.initials()
      } catch (error) {
        console.error('submitNote', error)
      } finally {
        this.loading = false
      }
    },
    async createNote (status) {
      const payload = {
        reason: this.reason,
        bcRef: this.bcRef,
        warehouse: this.warehouseSelected,
        products: this.productsSelected,
        note: this.note,
        purchaseOrderId: this.purchaseOrderId || '',
        assemblyOrderId: this.assemblyOrderId || '',
        status: status || 'pending'
      }
      if (this.stockAdjustmentNoteId) {
        payload['stockCountAdjustmentNoteRef'] = {
          stockCountAdjustmentId: this.stockAdjustmentNote.stockCountAdjustment,
          stockCountAdjustmentNoteId: this.stockAdjustmentNoteId
        }
      }
      const { data } = await InventoryService.createDeliveryNotes(payload)
      return data
    },
    async updateNote (status) {
      const { data } = await InventoryService.updateDeliveryNotes(this.noteId, {
        reason: this.reason,
        bcRef: this.bcRef,
        warehouse: this.warehouseSelected,
        products: this.productsSelected,
        note: this.note,
        purchaseOrderId: this.purchaseOrderId || '',
        assemblyOrderId: this.assemblyOrderId || '',
        status: status || 'pending'
      })
      return data
    },
    async updatePendingNote () {
      const { data } = await InventoryService.updatePendingDeliveryNotes(this.noteId, {
        reason: this.reason,
        bcRef: this.bcRef,
        warehouse: this.warehouseSelected,
        products: this.productsSelected,
        note: this.note,
        purchaseOrderId: this.purchaseOrderId || '',
        assemblyOrderId: this.assemblyOrderId || '',
        status: 'pending'
      })
      return data
    },
    async tryToUpdateNote (status) {
      if (status === 'cancel') {
        this.setModal({
          value: true,
          title: 'Cancel delivery note',
          message: 'Do you want to cancel this delivery note?',
          confirmText: 'Cancel',
          confirmType: 'error',
          cancelType: '',
          cancelText: 'Back',
          onConfirm: () => this.updateStatusNote(status)
        })
      } else {
        this.updateStatusNote(status)
      }
    },
    async updateStatusNote (status) {
      try {
        this.loading = true
        const { data } = await InventoryService.updateStatusDeliveryNotes({
          id: this.noteId,
          status
        }, {
          warehouse: this.warehouseSelected,
          products: this.productsSelected
        })
        this.setNoteData(data)
      } catch (error) {
        console.error('updateStatusNote', error)
        const message = error.message && error.message === 'Can not approve delivery notes because withdraw note status not equal approved.'
          ? 'โปรดแจ้งต้นทางให้ approve ของออกจาก WN ก่อน' : error.message
        this.setSnackbar({
          value: true,
          message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    },
    async fetchNote () {
      try {
        this.setLoading({ active: true })
        this.loading = true

        const { data } = await InventoryService.getDeliveryNoteById(this.noteId)
        const codes = data.products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        if (data?.purchaseOrderId) {
          await this.fetchPurchaseOrder(data.purchaseOrderId, true)
        }

        await this.getSkusAliasByCodes(codes)
        this.setNoteData(data)
      } catch (error) {
        console.error('fetchNote', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.setLoading({ active: false })
        this.validateForm()
      }
    },
    async fetchPurchaseOrder (id, onlyDate = false) {
      try {
        this.loading = true

        const { data } = await PurchaseOrdersService.getItemByOrderId(id)

        this.poCreateDate = data?.createdAt ? this.$dayjs(data.createdAt).format('MM-YYYY') : null

        if (onlyDate) {
          return
        }

        const models = data.type === 'FG'
          ? data.purchaseOrderLines.map((p) => p.itemNo)
          : data.purchaseOrderLines.reduce((arr, p) => [...arr, ...p.useForProducts], [])
        const modelIds = [...new Set(models)]
        // const collections = []
        const products = []

        if (data.type === 'FG') {
          const { data: foundProducts } = await ProductService.getManyProductByCode({ codes: modelIds })

          const poCollections = []

          data.purchaseOrderLines.forEach((purchaseOrderLine) => {
            poCollections.push(purchaseOrderLine.gwCollection)
            const foundProduct = foundProducts.find((s) => {
              const [brand, fac, cat, sub, running, color] = purchaseOrderLine.itemNo.split('.')
              const model = `${brand}.${fac}.${cat}.${sub}.${running}.${color}`

              return model === s.model
            })

            const foundSku = foundProduct.skus.find((fs) => fs.code === purchaseOrderLine.itemNo)

            const sku = {
              ...foundSku,
              color: foundSku.color.name,
              size: foundSku.size.name,
              count: 0,
              poQty: purchaseOrderLine.quantity,
              receivedQty: purchaseOrderLine?.receivedQty || 0,
              amount: purchaseOrderLine.quantity - (purchaseOrderLine?.receivedQty || 0),
              maxAmount: purchaseOrderLine.quantity - (purchaseOrderLine?.receivedQty || 0),
              sequence: purchaseOrderLine.sequence
            }

            const index = products.findIndex((p) => p.id === foundProduct.id)
            if (index === -1) {
              products.push({
                id: foundProduct.id,
                model: foundProduct.model,
                name: foundProduct.name,
                photoUrl: foundProduct.photoUrls[0] || '',
                variant: foundProduct.variant,
                skus: [sku]
              })
            } else {
              products[index].skus.push(sku)
            }
          })

          this.reason = 'PURCHASE_ORDER'
          this.note = [...new Set(poCollections.filter((collection) => !!collection))].join(', ')
        } else {
          const { data: foundProducts } = await ProductService.getManyProductByModel({ modelIds })

          foundProducts.forEach((p) => {
            const skus = p.skus.map((sku, i) => ({
              ...sku,
              color: sku.color.name,
              size: sku.size.name,
              count: 0,
              poQty: 0,
              receivedQty: 0,
              amount: 0,
              maxAmount: 0,
              sequence: (i + 1) * 10000
            }))

            products.push({
              id: p.id,
              model: p.model,
              name: p.name,
              photoUrl: p.photoUrls[0] || '',
              variant: p.variant,
              skus
            })
          })

          this.reason = 'ASSEMBLY_ORDER'
        }

        const codes = products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        await this.getSkusAliasByCodes(codes)

        this.productsSelected = products

        // if (!this.noteId) {
        //   this.note = [...new Set(collections)].join(', ')
        // }
      } catch (error) {
        console.error('fetchPurchaseOrder', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.validateForm()
      }
    },
    async setNoteData (data) {
      const products = data.products.map((product) => {
        let skus = this.sortSize(product.skus)

        if (data.withdrawNoteRef) {
          const wnProduct = data.withdrawNoteRef.products.find((p) => p.id === product.id)

          skus = skus.map((sku) => {
            const wnSku = wnProduct?.skus?.find((s) => s.id === sku.id) || null

            return {
              ...sku,
              maxAmount: wnSku?.count || sku.maxAmount
            }
          })
        }

        return {
          ...product,
          skus
        }
      })

      if (!!data?.purchaseOrderId && !this.purchaseOrderId && data?.reason === 'PURCHASE_ORDER') {
        this.$router.replace({
          name: 'DeliveryNote',
          query: {
            ...this.$route.query,
            poNo: data?.purchaseOrderId || null
          }
        })
      }

      if (!!data?.assemblyOrderId && !this.assemblyOrderId && data?.reason === 'ASSEMBLY_ORDER') {
        this.$router.replace({
          name: 'DeliveryNote',
          query: {
            ...this.$route.query,
            aoNo: data?.assemblyOrderId || null
          }
        })
      }

      this.qr = await generateQRCode(`${process.env.VUE_APP_URL}/notes/delivery-note?id=${data.id}&warehouse=${this.noteWarehouse}`)
      this.noteCode = data.code
      this.reason = data.reason || '-'
      this.bcRef = data.bcRef
      this.status = data.status
      this.noteRef = data.withdrawNoteRef
      this.createBy = data.createdBy.email
      this.updateBy = data.updatedBy.email
      this.updateTime = new Date(data.approvedDate).toString()
      this.note = data.note
      this.noteWarehouse = data.warehouse.id || 0
      this.productsSelected = []
      this.productsSelected.push(...products)
      this.transferWarehouseId = this.mapRef(data)
    },
    mapRef (data) {
      if (data.withdrawNoteRef && data.withdrawNoteRef.warehouseId) {
        return data.withdrawNoteRef.warehouseId
      }
      return null
    },
    print () {
      this.printTime = new Date().toString()
      setTimeout(() => {
        window.print()
      }, 100)
    },
    async handleAfterPrint () {
      try {
        await InventoryService.updatePrintedDeliveryNote(this.noteId)
        this.setSnackbar({
          value: true,
          message: 'Printed successfully',
          type: 'success'
        })
      } catch (error) {
        console.error('handleAfterPrint', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      }
    },
    approve () {
      this.valid = this.$refs.form.validate()
      if (!this.valid) {
        return
      }

      let isAlert = false
      for (let pInd = 0; pInd < this.productsSelected.length; pInd++) {
        const index = this.productsSelected[pInd].skus.findIndex((sku) => +sku.count !== +sku.amount)
        if (index !== -1) {
          isAlert = true
          break
        }
      }
      if (isAlert) {
        this.$store.dispatch('Components/setModal', {
          value: true,
          title: 'แจ้งเตือน !!',
          message: 'สินค้าที่รับเข้าไม่ตรงกับเอกสารจะยืนยันหรือไม่ ?',
          confirmText: 'ยืนยัน',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'ปิด',
          onConfirm: () => this.updateStatusNote('approve')
        })
      } else {
        this.setModal({
          value: true,
          title: 'ยืนยันการรับของ',
          message: 'โปรดนับจำนวนของให้ถูกต้องก่อนรับของ',
          confirmText: 'ยืนยัน',
          confirmType: 'success',
          cancelType: '',
          cancelText: 'ปิด',
          onConfirm: () => this.updateStatusNote('approve')
        })
      }
    },
    undoReceive () {
      this.$router.replace({
        name: 'WithdrawNote',
        query: {
          dnNo: this.noteId,
          warehouse: this.noteWarehouse
        }
      })
    },
    sortSize (skus) {
      return skus.sort((a, b) => SizeValues(a.size) - SizeValues(b.size))
    },
    async fetchStockAdjustmentNote () {
      try {
        this.loading = true
        const { data: stockAdjustmentNote } = await stockCountAdjustmentService.getStockAdjustmentNote({
          stockAdjustmentNoteId: this.stockAdjustmentNoteId,
          noteType: 'dn'
        })
        this.stockAdjustmentNote = stockAdjustmentNote
        const products = []
        stockAdjustmentNote.products.forEach((p) => {
          const skus = p.skus.map((sku, i) => ({
            ...sku,
            sequence: (i + 1) * 10000
          }))

          products.push({
            id: p.id,
            model: p.model,
            name: p.name,
            photoUrl: p.photoUrls || '',
            variant: p.variant,
            skus
          })
        })

        this.reason = stockAdjustmentNote.reason

        const codes = products.reduce((arr, v) => {
          const skuCodes = v.skus.map((s) => s.code)

          return [...arr, ...skuCodes]
        }, [])

        await this.getSkusAliasByCodes(codes)

        this.productsSelected = products

        if (!this.noteId) {
          this.note = stockAdjustmentNote.note
        }
      } catch (error) {
        console.error('fetchPurchaseOrder', error)
        this.setSnackbar({
          value: true,
          message: `Error: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.loading = false
        this.validateForm()
      }
    },
    async updateNoteInStockAdjustment ({
      noteId,
      noteCode,
      stockCountAdjustmentId,
      stockAdjustmentNoteId,
      noteType,
      skusInNote
    }) {
      const updated = await stockCountAdjustmentService.updateNoteInStockAdjustment({
        noteId,
        noteCode,
        stockCountAdjustmentId,
        stockAdjustmentNoteId,
        noteType,
        skusInNote
      })
      return updated
    }
  }
}
</script>

<style lang="scss" scoped>
.app-bar-title {
  display: flex;
  width: 230mm;
  margin: 0 auto;
  > button {
    margin: 0 5px;
  }
}
.page-a4-title {
  display: flex;
  align-items: center;
}
.warehouse-selector {
  width: 200px;
  font-size: 14px;
}
</style>
